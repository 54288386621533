<template>
    <div class="topper shimmer">
      <b-row class="text-center justify-content-center" align-v="center" :style="`min-height: ${heightCounter}vh;`">
        <b-col cols="2" class="text-right m-0 p-1">
          <b-img v-bind="logoProps" rounded="circle" src="/static/erkenn_logo-02-_2_.svg" alt="image slot"></b-img>
        </b-col>
        <b-col cols="7" sm="7" md="7" lg="3" class="text-left p-1" style="min-width: 100px;">
          <router-link :to="{ name: 'Home' }" class="router-link-custom"><h4 class="mb-0">ERKEN-CODE</h4></router-link>
        </b-col>
        <b-col cols="1" align-self="center" align="center" v-if="windowWidth > 990" @click="focusService()">
          <div class="cursor">УСЛУГИ</div>
        </b-col>
        <b-col cols="1" align-self="center" align="center" v-if="windowWidth > 990" @click="focusExamples()">
          <div class="cursor">ПРИМЕРЫ</div>
        </b-col>
        <b-col cols="3" align-self="center" align="center" v-if="windowWidth > 990" @click="focusContactInput()">
          <div class="wrap"><button class="button">КОНТАКТЫ</button></div>
        </b-col>
        <b-col cols="2" align-self="center" align="center"  v-if="windowWidth <= 990">
          <svg @click="menuClicked=!menuClicked" :class="menuClicked? 'unfolded' : 'folded'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list menu " viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
          </svg>
        </b-col>
        <!-- <b-col cols="2" class="text-center p-1">
          <b-button size="sm" variant="danger" @click="focusContactInput()">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-open icon" viewBox="0 0 16 16">
              <path d="M8.47 1.318a1 1 0 0 0-.94 0l-6 3.2A1 1 0 0 0 1 5.4v.817l5.75 3.45L8 8.917l1.25.75L15 6.217V5.4a1 1 0 0 0-.53-.882l-6-3.2ZM15 7.383l-4.778 2.867L15 13.117V7.383Zm-.035 6.88L8 10.082l-6.965 4.18A1 1 0 0 0 2 15h12a1 1 0 0 0 .965-.738ZM1 13.116l4.778-2.867L1 7.383v5.734ZM7.059.435a2 2 0 0 1 1.882 0l6 3.2A2 2 0 0 1 16 5.4V14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5.4a2 2 0 0 1 1.059-1.765l6-3.2Z"/>
            </svg>
          </b-button>
        </b-col> -->
      </b-row>
      <transition name="slide-navbar" mode="in-out">
        <b-row class="text-center justify-content-center" align-v="center" v-show="menuClicked">
          <b-col cols="10" class="text-center p-1"><div class="my-2 cursor" @click="focusService()">УСЛУГИ</div></b-col>
          <b-col cols="10" class="text-center p-1"><div class="my-2 cursor" @click="focusExamples()">ПРИМЕРЫ</div></b-col>
          <b-col cols="10" class="text-center p-1"><div class="my-2 cursor" @click="focusContactInput()"><div class="wrap"><button class="button">КОНТАКТЫ</button></div></div></b-col>
        </b-row>
      </transition>
    </div>
</template>

<script>

export default {
  props: ['heightCounter'],
  name: "NavbarComponent",
  data() {
    return {
      requestUser: null,
      logoProps: { blankColor: '#777', width: 50, height: 50, class: 'bg-white' },
      windowWidth: window.innerWidth,
      menuClicked: false
    }
  },
  methods: {
    focusContactInput() {
      this.$root.$emit('focusContactInput')
      this.menuClicked = false
    },
    focusService() {
      this.$root.$emit('focusService')
      this.menuClicked = false
    },
    focusExamples() {
      this.$root.$emit('focusExamples')
      this.menuClicked = false
    }
  },
  created() {
    
  },
  mounted() {
    window.onresize = () => {
        this.windowWidth = window.innerWidth
    }
  },
  computed: {
  },
  watch: {
    windowWidth() {
      if (this.windowWidth > 990) {
        this.menuClicked = false
      }
    }
  }

};

</script>

<style>
.menu {
  width: 30px;
  height: 30px;
  border-radius: 3px;
}
.folded {
  color: white;
  width: 30px;
  height: 30px;
}
.unfolded {
  color: rgb(49, 49, 49);
  background-color: white;
  width: 30px;
  height: 30px;
}

.topper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  /* background-color: red; */
  color: white;
  text-align: center;
  background-image: radial-gradient(rgb(140, 140, 140), rgb(0, 0, 0));
  overflow: hidden;
  z-index: 2;
}
.router-link-custom {
  color: white; 
  cursor: pointer;
}
.router-link-custom:hover {
  color: #d8d8d8 !important;
}
</style>
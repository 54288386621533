<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-show="startAnimation">
        <div class="parallax" v-bind:style="{ 'background-image': 'url(' + '/static/background3.jpg' + ')' }" >
          <b-container >
            <b-row class="text-center justify-content-center" align-v="center" id="welcome">
              <b-col cols="12" sm="12" md="12" lg="12" align-self="center">
                <div class="text-center" >
                  <h4>РАЗРАБОТКА РЕШЕНИЙ ДЛЯ АВТОМАТИЗАЦИИ БИЗНЕСА</h4>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </div>
    </transition>
    <div class="">
      <div class="py-5" ref="service">
        <transition name="slide" mode="out-in">
          <div v-show="startAnimation" class="">
            <b-container class="p-2">
              <b-row class="text-center justify-content-center">
                <h4 class="text-center"> УСЛУГИ</h4>
              </b-row>
              <b-row class="text-center justify-content-center mb-5 mt-1">
                <b-col cols="12" sm="10" md="8">
                  <b-button class="pt-2 shadow2"  block v-b-toggle="'service-1'" variant="outline-dark" @click="collapsState1=!collapsState1">
                    <span class="">ВЕБ-ПРИЛОЖЕНИЯ</span> 
                    <span class="float-right">
                      <span v-if="collapsState1">
                        <i class="arrow down mb-1"></i>
                      </span>
                      <span v-else>
                        <i class="arrow up"></i>
                      </span>
                    </span>
                  </b-button>
                  <b-collapse id="service-1" class="text-left">
                    <div class="m-4">
                      <ul>
                        <li>Создание сервисов с личными кабинетами пользователей и правами доступа</li>
                        <li>Оповещения пользователей через почту или телеграм бота</li>
                        <li>Реализация обмена данными в реальном времени</li>
                        <li>Графическое представление и анализ данных, дашборды</li>
                        <li>Адаптивный дизайн (размещение элементов интерфейса зависит от размера экрана устройства)</li>
                        <li>Варианты размещения приложения: сторонний облачный сервер или сервер ERKEN-CODE</li>
                      </ul>
                    </div> 
                  </b-collapse>
                </b-col>
              </b-row>
              <b-row class="text-center justify-content-center my-5">
                <b-col cols="12" sm="10" md="8">
                  <b-button class="pt-2 shadow2" block v-b-toggle="'service-2'" variant="outline-dark" @click="collapsState2=!collapsState2">
                    <span class="">СКРИПТЫ</span> 
                    <span class="float-right">
                      <span v-if="collapsState2">
                        <i class="arrow down mb-1"></i>
                      </span>
                      <span v-else>
                        <i class="arrow up"></i>
                      </span>
                    </span>
                  </b-button>
                  <b-collapse id="service-2" class="text-left">
                    <div class="m-4">
                      <ul>
                        <li>Программа с пользовательским интерфейсом или отдельный исполняемый скрипт</li>
                        <li>Получение данных из внешних API и загружаемых документов</li>
                        <li>Выполнение рабочей логики</li>
                        <li>Создание документов с обработанными данными или отправка в Google sheets</li>
                      </ul>
                    </div> 
                  </b-collapse>
                </b-col>
              </b-row>
              <b-row class="text-center justify-content-center my-5">
                <b-col cols="12" sm="10" md="8">
                  <b-button class="pt-2 shadow2" block v-b-toggle="'service-3'" variant="outline-dark" @click="collapsState3=!collapsState3">
                    <span class="">ДАШБОРДЫ</span> 
                    <span class="float-right">
                      <span v-if="collapsState3">
                        <i class="arrow down mb-1"></i>
                      </span>
                      <span v-else>
                        <i class="arrow up"></i>
                      </span>
                    </span>
                  </b-button>
                  <b-collapse id="service-3" class="text-left">
                    <div class="m-4">
                      <ul>
                        <li>Подключение к вашему API из Google Sheets или Python скрипта</li>
                        <li>Выполнение рабочей логики</li>
                        <li>Выгрузка данных в Google Sheets</li>
                        <li>Создание дашбордов в Google Data Studio</li>
                      </ul>
                    </div> 
                  </b-collapse>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </transition>
      </div>
      <div class="py-5 shadow4" >
        <div class="container" >
          <div class="col-12 text-center mb-3"><h4 class="text-center">ТЕХНОЛОГИИ, КОТОРЫМ МЫ ДОВЕРЯЕМ</h4></div>
          <b-container class="mt-2">
            <b-row class="text-center">
              <b-col class="shadow2 rounded moveable-up">
                <b-img v-bind="mainProps" rounded src="/static/python.png" alt="image slot"></b-img>
                <div><small>Python</small></div>
              </b-col>
              <b-col class="shadow2 rounded moveable-up">
                <b-img v-bind="mainProps" rounded src="/static/java-script.png" alt="image slot"></b-img>
                <div><small>JavaScript</small></div>
              </b-col>
              <b-col class="shadow2 rounded moveable-up">
                <b-img v-bind="mainProps" rounded src="/static/dj.png" alt="image slot"></b-img>
                <div><small>Django</small></div>
              </b-col>
              <b-col class="shadow2 rounded moveable-up">
                <b-img v-bind="mainProps" rounded src="/static/api.png" alt="image slot"></b-img>
                <div><small>DRF</small></div>
              </b-col>
              <b-col class="shadow2 rounded moveable-up">
                <b-img v-bind="mainProps" rounded src="/static/vue.png" alt="image slot"></b-img>
                <div><small>Vue.js</small></div>
              </b-col>
              <b-col class="shadow2 rounded moveable-up">
                <b-img v-bind="mainProps" rounded src="/static/postgre.png" alt="image slot"></b-img>
                <div><small>PostgreSQL</small></div>
              </b-col>
              <b-col class="shadow2 rounded moveable-up">
                <b-img v-bind="mainProps" rounded src="/static/bootstrap.png" alt="image slot"></b-img>
                <div><small>Bootstrap</small></div>
              </b-col>
              <b-col class="shadow2 rounded moveable-up">
                <b-img v-bind="mainProps" rounded src="/static/docker2.png" alt="image slot"></b-img>
                <div><small>Docker</small></div>
              </b-col>
              <b-col class="shadow2 rounded moveable-up">
                <b-img v-bind="mainProps" rounded src="/static/server.png" alt="image slot"></b-img>
                <div><small>Cloud</small></div>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </div>

      <div class="py-5">
        <b-container>
          <b-row class="text-center justify-content-center mb-3">
            <h4 class="text-center">СТАДИИ РАЗРАБОТКИ ВЕБ-ПРИЛОЖЕНИЯ</h4>
          </b-row>
          <b-row class="text-center justify-content-center my-1 stage-row-height">
            <b-col cols="2" sm="1" align-self="center" align="center"  class="p-0  moveable-down" v-b-toggle="['accordion-1', 'accordion-1.1']"  @click="collapsState1Dev=!collapsState1Dev">
              <div class="sphere">
                1
              </div>
            </b-col>
            <b-col cols="9" md="9" align-self="center" align="center" class="px-1">
              <b-button class="shadow2" size="sm" block v-b-toggle="['accordion-1', 'accordion-1.1']" variant="outline-dark" @click="collapsState1Dev=!collapsState1Dev">
                <span class="">Сбор требований и разработка ТЗ</span> 
                <span class="float-right">
                  <span v-if="collapsState1Dev">
                    <i class="arrow down"></i>
                  </span>
                  <span v-else>
                    <i class="arrow up"></i>
                  </span>
                </span>
              </b-button>
            </b-col>
          </b-row>
          <b-row class="text-center justify-content-center my-1">
            <b-col cols="2" sm="1"  align="center"  class="p-0 rounded">
              <div class="h-100 btn-block text-center"
                style="background: linear-gradient(#1fa3b7, #000000) no-repeat center/4px 85%;"
              >
                <b-collapse id="accordion-1.1" style="position: absolute; bottom: 0; width: 100%; text-align: center;">
                  <i class="arrow down"></i>
                </b-collapse>
              </div>
            </b-col>
            <b-col cols="9" md="9" class="pr-1 shadow-right">
              <b-collapse id="accordion-1" class="text-left">
                <div class="m-2 small-text">
                  <p>Всё начинается с набора идей, которые постепенно превращаются в подробный документ, в котором перечислены цели приложения, функции, технологии, бюджет, видение и планы на будущее.</p>
                  <p>Изучив этот документ, команда разработчиков получит четкое представление о целях вашего приложения, ключевых задачах, целевой аудитории, целевой отрасли, вехах и других важных элементах. За этим документом следуют обсуждения и анкеты, которые помогают веб-разработчикам получить более четкое представление о целях проекта.</p>
                  <p>После того, как команда разработчиков приложения получит 100% ясность во всем, что связано с проектом, предложение будет готово для документирования всего, что будет реализовано.</p>
                </div> 
              </b-collapse>
            </b-col>
          </b-row>
          <b-row class="text-center justify-content-center my-1 stage-row-height">
            <b-col cols="2" sm="1"  align-self="center" align="center"  class="p-0 rounded-circle moveable-down" v-b-toggle="['accordion-2', 'accordion-2.1']"  @click="collapsState2Dev=!collapsState2Dev">
              <div class="sphere">
                2
              </div>
            </b-col>
            <b-col cols="9" md="9" align-self="center" align="center" class="px-1">
              <b-button class="shadow2" size="sm" block v-b-toggle="['accordion-2', 'accordion-2.1']" variant="outline-dark" @click="collapsState2Dev=!collapsState2Dev">
                <span class="">Планирование и разработка макетов</span> 
                <span class="float-right">
                  <span v-if="collapsState2Dev">
                    <i class="arrow down"></i>
                  </span>
                  <span v-else>
                    <i class="arrow up"></i>
                  </span>
                </span>
              </b-button>
            </b-col>
          </b-row>
          <b-row class="text-center justify-content-center my-1">
            <b-col cols="2" sm="1"  class="p-0 rounded">
              <div class="h-100 btn-block text-center"
                style="background: linear-gradient(#1fa3b7, #000000) no-repeat center/4px 85%;"
              >
                <b-collapse id="accordion-2.1" style="position: absolute; bottom: 0; width: 100%; text-align: center;">
                  <i class="arrow down"></i>
                </b-collapse>
              </div>
            </b-col>
            <b-col cols="9" md="9" class="pr-1 shadow-right">
              <b-collapse id="accordion-2" class="text-left">
                <div class="m-2 small-text">
                  <p>На этапе 1 стороны достигли понимания предполагаемого веб-приложения. Теперь пришло время создать дорожную карту. С помощью информации, полученной на предыдущем этапе модели разработки веб-приложения, разработчики создают план, включающий блок-схемы и эскизы, которые помогают определить общую структуру веб-приложения.</p>
                  <p>Блок-схемы, также известные как карты сайта, показывают взаимосвязь между различными веб-страницами и помогают понять, как будет выглядеть и работать внутренняя структура вашего веб-сайта. Эскизы используются для визуального представления пользовательского интерфейса.</p>
                  <p>В зависимости от сложности веб-приложения время, затрачиваемое на этап 2, будет варьироваться. Если вы создаете минимально жизнеспособный продукт, будьте готовы посвятить этому около 2 недель.</p>
                </div> 
              </b-collapse>
            </b-col>
          </b-row>
          <b-row class="text-center justify-content-center my-1 stage-row-height">
            <b-col cols="2" sm="1" align-self="center" align="center"  class="p-0 rounded-circle moveable-down" v-b-toggle="['accordion-3', 'accordion-3.1']" @click="collapsState3Dev=!collapsState3Dev">
              <div class="sphere">
                3
              </div>
            </b-col>
            <b-col cols="9" md="9" align-self="center" align="center" class="px-1">
              <b-button class="shadow2" size="sm" block v-b-toggle="['accordion-3', 'accordion-3.1']" variant="outline-dark" @click="collapsState3Dev=!collapsState3Dev">
                <span class="">Дизайн</span> 
                <span class="float-right">
                  <span v-if="collapsState3Dev">
                    <i class="arrow down"></i>
                  </span>
                  <span v-else>
                    <i class="arrow up"></i>
                  </span>
                </span>
              </b-button>
            </b-col>
          </b-row>
          <b-row class="text-center justify-content-center my-1">
            <b-col cols="2" sm="1"  class="p-0 rounded">
              <div class="h-100 btn-block text-center"
                style="background: linear-gradient(#1fa3b7, #000000) no-repeat center/4px 85%;"
              >
                <b-collapse id="accordion-3.1" style="position: absolute; bottom: 0; width: 100%; text-align: center;">
                  <i class="arrow down"></i>
                </b-collapse>
              </div>
            </b-col>
            <b-col cols="9" md="9" class="pr-1 shadow-right">
              <b-collapse id="accordion-3" class="text-left">
                <div class="m-2 small-text">
                  <p>Пользователи веб-приложений не знают, что происходит за интерфейсом приложения и как все работает. Все, с чем они взаимодействуют — это часть дизайна приложения. Стадия 3 посвящена совершенствованию дизайна и интерактивным элементам веб-приложения. Дизайнеры работают с цветовыми схемами, графикой, значками, шаблонами, пользовательским интерфейсом, руководствами по стилю, переходами, кнопками и многим другим.</p>
                  <p>После окончательной доработки первоначальных макетов они передаются клиентам для обратной связи. Итерации изменения дизайна не ограничены, но после второй оплачиваются отдельно. Пока команда дизайнеров занята макетами и доработками, команда разработчиков занята программированием. Таким образом, Стадия 3 и Стадия 4 обычно продвигаются вперед параллельно друг другу.</p>
                </div> 
              </b-collapse>
            </b-col>
          </b-row>
          <b-row class="text-center justify-content-center my-1 stage-row-height">
            <b-col cols="2" sm="1" align-self="center" align="center"  class="p-0 rounded-circle moveable-down" v-b-toggle="['accordion-4', 'accordion-4.1']" @click="collapsState4Dev=!collapsState4Dev">
              <div class="sphere">
                4
              </div>
            </b-col>
            <b-col cols="9" md="9" align-self="center" align="center" class="px-1">
              <b-button class="shadow2" size="sm" block v-b-toggle="['accordion-4', 'accordion-4.1']" variant="outline-dark" @click="collapsState4Dev=!collapsState4Dev">
                <span class="">Программирование</span> 
                <span class="float-right">
                  <span v-if="collapsState4Dev">
                    <i class="arrow down"></i>
                  </span>
                  <span v-else>
                    <i class="arrow up"></i>
                  </span>
                </span>
              </b-button>
            </b-col>
          </b-row>
          <b-row class="text-center justify-content-center my-1">
            <b-col cols="2" sm="1"  class="p-0 rounded">
              <div class="h-100 btn-block text-center"
                style="background: linear-gradient(#1fa3b7, #000000) no-repeat center/4px 85%;"
              >
                <b-collapse id="accordion-4.1" style="position: absolute; bottom: 0; width: 100%; text-align: center;">
                  <i class="arrow down"></i>
                </b-collapse>
              </div>
            </b-col>
            <b-col cols="9" md="9" class="pr-1 shadow-right">
              <b-collapse id="accordion-4" class="text-left">
                <div class="m-2 small-text">
                  <p>Если Стадия 3 создает эстетику автомобиля, то Стадия 4 занимается сборкой двигателя. Программирование приложений воплощает задуманные функции и создает компонент ценности для клиентов. На этом этапе разрабатываются фреймворки, развертываются API, создаются функции приложений, добавляются уровни безопасности и многое другое.</p>
                  <p>Хотя кодирование сложных веб-приложений — это трудоемкий процесс, многое зависит от выбранных технологий, опыта и знаний веб-программистов, работающих над вашим веб-приложением. Этап 4 формирует самую большую часть модели веб-приложения.</p>
                </div> 
              </b-collapse>
            </b-col>
          </b-row>
          <b-row class="text-center justify-content-center my-1 stage-row-height">
            <b-col cols="2" sm="1" align-self="center" align="center"  class="p-0 rounded-circle moveable-down" v-b-toggle="['accordion-5', 'accordion-5.1']" @click="collapsState5Dev=!collapsState5Dev">
              <div class="sphere">
                5
              </div>
            </b-col>
            <b-col cols="9" md="9" align-self="center" align="center" class="px-1">
              <b-button class="shadow2" size="sm" block v-b-toggle="['accordion-5', 'accordion-5.1']" variant="outline-dark" @click="collapsState5Dev=!collapsState5Dev">
                <span class="">Тестирование и запуск</span> 
                <span class="float-right">
                  <span v-if="collapsState5Dev">
                    <i class="arrow down"></i>
                  </span>
                  <span v-else>
                    <i class="arrow up"></i>
                  </span>
                </span>
              </b-button>
            </b-col>
          </b-row>
          <b-row class="text-center justify-content-center my-1">
            <b-col cols="2" sm="1"  class="p-0 rounded">
              <div class="h-100 btn-block text-center"
                style="background: linear-gradient(#1fa3b7, #000000) no-repeat center/4px 85%;"
              >
                <b-collapse id="accordion-5.1" style="position: absolute; bottom: 0; width: 100%; text-align: center;">
                  <i class="arrow down"></i>
                </b-collapse>
              </div>
            </b-col>
            <b-col cols="9" md="9" class="pr-1 shadow-right">
              <b-collapse id="accordion-5" class="text-left">
                <div class="m-2 small-text">
                  <p>Тестирование приложения после того, как все кажется готовым к работе, является наиболее важным аспектом модели разработки веб-приложений.</p>
                  <p>Даже после двойного тестирования рекомендуется сначала запустить веб-приложение в бета-версии.</p>
                  <p>Варианты размещения приложения: сторонний облачный сервер или сервер ERKEN-CODE.</p>
                </div> 
              </b-collapse>
            </b-col>
          </b-row>
          <b-row class="text-center justify-content-center my-1 stage-row-height">
            <b-col cols="2" sm="1"  align-self="center" align="center" class="p-0 rounded-circle moveable-down" v-b-toggle="['accordion-6', 'accordion-6.1']" @click="collapsState6Dev=!collapsState6Dev">
              <div class="sphere">
                6
              </div>
            </b-col>
            <b-col cols="9" md="9" align-self="center" align="center" class="px-1">
              <b-button class="shadow2" size="sm" block v-b-toggle="['accordion-6', 'accordion-6.1']" variant="outline-dark" @click="collapsState6Dev=!collapsState6Dev">
                <span class="">Обслуживание</span> 
                <span class="float-right">
                  <span v-if="collapsState6Dev">
                    <i class="arrow down"></i>
                  </span>
                  <span v-else>
                    <i class="arrow up"></i>
                  </span>
                </span>
              </b-button>
            </b-col>
          </b-row>
          <b-row class="text-center justify-content-center my-1">
            <b-col cols="2" sm="1"  class="p-0 rounded">

            </b-col>
            <b-col cols="9" md="9" class="pr-1">
              <b-collapse id="accordion-6" class="text-left">
                <div class="m-2 small-text">
                  <p>Будь то простой бизнес-сайт или сложное веб-приложение, каждый цифровой продукт нуждается в плановых проверках и улучшениях.</p>
                </div> 
              </b-collapse>
            </b-col>
          </b-row>

        </b-container>
      </div>
      <div class="pb-4">
        <div class="container">
          <b-row class="text-center">
            <b-col class="moveable-up">
              <b-img v-bind="mainProps" rounded src="/static/agile2.png" alt="image slot"></b-img>
              <div><small>Гибкость</small></div>
            </b-col>
            <b-col class="moveable-up">
              <b-img v-bind="mainProps" rounded src="/static/security.png" alt="image slot"></b-img>
              <div><small>Безопасность</small></div>
            </b-col>
            <b-col class="moveable-up">
              <b-img v-bind="mainProps" rounded src="/static/speed.png" alt="image slot"></b-img>
              <div><small>Производительность</small></div>
            </b-col>
            <b-col class="moveable-up">
              <b-img v-bind="mainProps" rounded src="/static/clock.png" alt="image slot"></b-img>
              <div><small>Сроки</small></div>
            </b-col>
          </b-row>
        </div>
      </div>

      <div class="py-5 shadow5" ref="examples">
        <div class="container" >
          <div class="row justify-content-center">
            <div class="col-12 text-center mb-2"><h4 class="text-center">НАШИ РАБОТЫ</h4></div>
            <div class="mt-2 col-10 col-sm-10 col-md-8 col-lg-5 shadow2  rounded p-4 m-3 exhibition">
              <h5 class="text-center mt-2"><strong>Accounting Ledger</strong></h5>
              Система контроля семейного бюджета. Особенности:
              <ul>
                <li>Возможность создавать счета, просматривать отчёты</li>
                <li>Доступ к записям расходов членов семьи</li>
                <li>Стек: Django + Vue + PostgreSQL</li>
                <li>Развернут с Docker на сервере ERKEN-CODE</li>
              </ul>
              <b-button class="text-center shadow1 mb-3" href="https://ledger.erken-code.ru/" variant="">ПЕРЕЙТИ</b-button>
            </div>
            <div class="mt-2 col-10 col-sm-10 col-md-8 col-lg-5 shadow2  rounded p-4 m-3 exhibition">
              <h5 class="text-center mt-2"><strong>Голос внутри</strong></h5>
              Веб-приложение для финансового мониторинга бизнеса. Особенности:
              <ul>
                <li>Подключение франшиз в качестве новых пользователей и контроль отчислений</li>
                <li>Группы доступа к каждому разделу приложения</li>
                <li>Дашборды</li>
                <li>Django, Vue, DRF</li>
                <li>Облачное размещение</li>
              </ul>
            </div>
            <div class="mt-2 col-10 col-sm-10 col-md-8 col-lg-8 shadow2  rounded p-4 m-3 exhibition">
              <h5 class="text-center mt-2"><strong>ДВ Экспертиза проект</strong></h5>
              Портал. Это система автоматизации бизнес процессов,
              включающая в себя разделы по электронному документообороту, учёту фонда оплаты труда
              и визуальному представлению информации из базы данных (загруженность работников,
              статистика выполнения задач). В Портале задействованы:
              <ul>
                <li>Обмен данными в реальном времени между онлайн пользователями в группе через системные сообщения в чате и внутренний api</li>
                <li>Три роли пользователей (менеджеры, эксперты, заказчики)</li>
                <li>Разные интерфейсы и функционал ролей</li>
                <li>Django, vue, drf, channels, redis</li>
                <li>API - интеграция amo-crm, nalog.ru</li>
                <li>Дашборды</li>
                <li>Система рассылки оповещений о требуемых действиях каждому пользователю</li>
              </ul>
            </div>
            <!-- <div class="col-12 text-center mt-4">
                <b-button class="m-2 shadow2" variant="">1</b-button>
                <b-button class="m-2 shadow2" variant="">2</b-button>
                <b-button class="m-2 shadow2" variant="">3</b-button>
              
            </div> -->
          </div>
        </div>
      </div>

      <div class="py-5  shadow1" >
        <div class="container">
          <h4 class="col-12 text-center" >СВЯЗАТЬСЯ С НАМИ</h4>
          <div class="container small-text mt-3">
            <div class="row justify-content-center mt-3">
              <transition name="fade" mode="out-in">
                <b-alert class="py-1  shadow2" :show="dismissCountDown" fade :variant="saveMessageStatus=='200'? 'success': 'danger'" @dismiss-count-down="countDownChanged">
                  <div v-for="(value, index) in saveMessageList" :key="index">
                    <p>{{ value }}</p>
                  </div>
                  <b-progress
                    variant="warning"
                    :max="dismissSecs"
                    :value="dismissCountDown"
                    height="4px"
                  ></b-progress>
                </b-alert>
              </transition>
            </div>
            <div class="container">
              <div class="row mt-1  justify-content-center">
                <div class="col-12 col-sm-12 col-md-3 text-left">
                  <p class="div-text-vert-center">Имя*</p>
                </div>
                <div class="col-12 col-sm-12 col-md-5 col-lg-4 text-center  my-1">
                  <input ref="contactInput" type="text" class="form-control small-text shadow2" :class="feedback.clicked? (feedback.name? 'valid-input': 'invalid-input') : ''" v-model="feedback.name">
                </div>
              </div>
              <div class="row mt-1 justify-content-center">
                <div class="col-12 col-sm-12 col-md-3 text-left">
                  <p class="div-text-vert-center">Email*</p>
                </div>
                <div class="col-12 col-sm-12 col-md-5 col-lg-4 text-center  my-1">
                  <input type="email" class="form-control small-text shadow2" v-model="feedback.email" :class="feedback.clicked? (feedback.email?.includes('@') ? 'valid-input': 'invalid-input') : ''">
                </div>
              </div>
              <div class="row mt-1 justify-content-center">
                <div class="col-12 col-sm-12 col-md-3 text-left">
                  <p class="div-text-vert-center">Сообщение*</p>
                </div>
                <div class="col-12 col-sm-12 col-md-5 col-lg-4 text-center my-1">
                  <textarea class="form-control small-text shadow2"
                    v-model="feedback.text"
                    :class="feedback.clicked? (feedback.text? 'valid-input': 'invalid-input') : ''"
                    rows="3"
                    max-rows="6"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="container mt-3 small-text" >
              <div class="row justify-content-center">
                <transition name="fade" mode="in-out">
                  <b-alert class="py-1 shadow2" :show="nextPostDismissCountDown" fade variant="danger" @dismiss-count-down="nextPostCountDownChanged">
                    <p>Следующая отправка доступна через {{ nextPostDismissCountDown }} сек.</p>
                    <b-progress
                      variant="warning"
                      :max="nextPostDismissSecs"
                      :value="nextPostDismissCountDown"
                      height="4px"
                    ></b-progress>
                  </b-alert>
                </transition>
              </div>
              <transition name="fade">
                <div class="row justify-content-center mt-2" v-if="!nextPostDismissCountDown">
                  <div class="wrap"><button class="button" @click="createFeedback()">ОТПРАВИТЬ</button></div>
                </div>
              </transition>
            </div>
            <div class="container my-4">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { apiService } from "@/common/api.service.js";
import { CSRF_TOKEN } from "@/common/csrf_token.js"
export default {
  name: "Home",
  components: {

  },
  // props: {
  //   overlay: Boolean
  // },
  data() {
    return {
      overlay: false,
      startAnimation: false,
      mainProps: { blankColor: '#777', width: 75, height: 75, class: 'm-2' },
      dismissSecs: 4,
      dismissCountDown: 0,
      nextPostDismissSecs: 30,
      nextPostDismissCountDown: 0,
      saveMessageList: [],
      saveMessageStatus: 200,
      feedback: {
        name: '',
        email: '',
        text: '',
        clicked: false
      },
      collapsState1: true,
      collapsState2: true,
      collapsState3: true,
      collapsState1Dev: true,
      collapsState2Dev: true,
      collapsState3Dev: true,
      collapsState4Dev: true,
      collapsState5Dev: true,
      collapsState6Dev: true,
    }
  },
  methods: {
    async changeOverlay() {
      this.overlay = !this.overlay
      this.$emit('eventname', this.overlay)
      await this.delay(0.5)
      this.overlay = !this.overlay
      this.$emit('eventname', this.overlay)
    },
    delay(n){
      return new Promise(function(resolve){
          setTimeout(resolve,n*1000);
      });
    },
    async createFeedback() {
      this.feedback.clicked = true
      if (this.feedback.name && this.feedback.email && this.feedback.text) {
        this.overlay = true
        await this.delay(0.5)
        const config = {
          method: 'GET',
          body: null,
          headers: {
            'content-type': 'application/json',
            'X-CSRFTOKEN': CSRF_TOKEN
          }
        };
        let endpoint = `records/api-feedback-save/`
        endpoint += `?name=${this.feedback.name}&email=${this.feedback.email}&text=${this.feedback.text}`

        let data = await fetch(endpoint, config)
        // console.log("data", data)
        this.saveMessageStatus = data?.status
        let res = await data.json()
        this.saveMessageList = res.result
        this.showAlert()
        if (this.saveMessageStatus==200) {
          this.feedback.name = ''
          this.feedback.email = ''
          this.feedback.text = ''
          this.feedback.clicked = false
          this.nextPostShowAlert()
        }
      } else {
        this.saveMessageStatus = 400
        this.saveMessageList = ['Заполните все поля']
        this.showAlert()
      }
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    nextPostShowAlert() {
      this.nextPostDismissCountDown = this.nextPostDismissSecs
    },
    nextPostCountDownChanged(nextPostDismissCountDown) {
      this.nextPostDismissCountDown = nextPostDismissCountDown
    },
  },
  mounted() {
    this.startAnimation = true
    this.$root.$on('focusContactInput', () => this.$refs.contactInput.scrollIntoView({behavior: 'smooth'}))
    this.$root.$on('focusService', () => this.$refs.service.scrollIntoView({behavior: 'smooth'}))
    this.$root.$on('focusExamples', () => this.$refs.examples.scrollIntoView({behavior: 'smooth'}))
  },
  created() {
    document.title = "ERKEN-CODE";
  },
  computed: {
  },
  watch: {
  }
};
</script>

<style scoped>
.sphere {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #1fa3b7;
  display: table-cell;
  vertical-align: middle;
  font-size: 1.5em;
  color: white;
}
.valid-input {
  border-color: green;
}
.invalid-input {
  border-color: red;
}
.client-border {
  border: 2px solid rgb(255, 255, 255);
  border-radius: 10px;
}
.parallax {
  /* Set a specific height */
  height: 100vh;
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  animation: gradient 200s ease infinite;
}
@keyframes gradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

#welcome{
  height: 100vh;
  color: rgb(255, 255, 255);

}




</style>

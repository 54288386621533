<template>
  <div class="centered">
    <transition name="bounce">
      <h1 v-if="show">
        <div>404</div>
        <div>НЕ НАЙДЕНА</div>
        <div>😢</div> 
      </h1>
    </transition>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  data() {
    return {
      message: "404 - Page Not Found",
      show: false
    }
  },
  mounted() {
    this.show = true; // might need this.$nextTick
  }  
}
</script>

<style scoped>

.centered {
  height:100vh;
  padding-top: 30vh;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}
</style>



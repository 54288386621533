<template>
  <div id="app">
    <b-overlay :show="overlay" rounded="sm">
      <div class="demo-wrap my-font">
        <NavbarComponent :heightCounter="heightCounter"/>
        <div class="container">
          <img class="demo-bg" src="/static/erkenn_logo-02-_2_.svg" alt="image slot">
        </div>
        <div class="demo-content" v-if="heightCounter==0">
          <transition name="router-anim" mode="out-in">
            <router-view @eventname="updateParentOverlay"/>
          </transition>
        </div>
        <FooterComponent :heightCounter="heightCounter"/>
      </div>
    </b-overlay>
    
  </div>
</template>

<script>
// import { apiService } from "@/common/api.service.js";
import NavbarComponent from "@/components/Navbar.vue";
import FooterComponent from "@/components/Footer.vue";
export default {
  name: "App",
  components: {
    NavbarComponent,
    FooterComponent
  },
  data() {
    return {
      overlay: false,
      heightCounter: 50,
    }
  },
  methods: {
    countDown() {
      const myInterval = setInterval(() => {
        this.heightCounter -=0.5
        if(this.heightCounter <= 0){
          clearInterval(myInterval);
        }
      }, 10);
    },
    delay(n){
      return new Promise(function(resolve){
          setTimeout(resolve,n*1000);
      });
    },
    updateParentOverlay(variable) {
      this.overlay = variable
    },
    async waitForData() {
      this.overlay = true
      await this.delay(0.5)
      this.overlay = false
    }
  },
  created() {
    this.countDown()
    // this.waitForData()
  },

};
</script>

<style>
.exhibition {
  /* transition: all 0.75s cubic-bezier(0.68, -0.55, 0.265, 1.55); */
  transition: all 0.3s ease 0s;
}
.exhibition:hover {
  transform: scale(0.99, 0.99);
  -webkit-transform: scale(0.99, 0.99);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}
.cursor {
  cursor: pointer;
  transition: all 0.2s ease 0s;
}
.cursor:hover {
  transform: scale(1.05, 1.05);
  -webkit-transform: scale(1.05, 1.05);
}


.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}
.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}




.moveable-up {
  transition: all 0.3s ease 0s;
}
.moveable-up:hover {
  transform: translateY(-7px);
}
.moveable-down {
  transition: all 0.3s ease 0s;
}
.moveable-down:hover {
  transform: translateY(7px);
}

.my-font {
  font-family: 'Montserrat',sans-serif !important; 
  font-weight: 400;
}
.wrap {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  width: 140px;
  height: 45px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  color: #d8d8d8;
  background-color: #d82b3f;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  }

.button:hover {
  background-color: #ff4747;
  box-shadow: 0px 15px 20px rgba(229, 46, 46, 0.486);
  color: #fff;
  transform: translateY(-7px);
}
.shadow1 {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.shadow2 {
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.shadow3 {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.shadow4 {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
}
.shadow5 {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}
.shadow6 {
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.shadow-right {
  box-shadow: 10px 3px 5px -2px rgb(218, 218, 218);
}


.stage-row-height {
  height: 53px;
}
.icon {
    height: 25px !important;
    width: 25px !important;
    vertical-align: middle !important;
  }
a {
  color: white !important;
  cursor: pointer !important;
}
a:hover {
  color: #d8d8d8 !important;
}
.div-text-vert-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);  
}

.small-text { 
    font-size: 0.8rem !important;
  }
html {
  background-color: rgb(246, 246, 246) !important;
  /* margin-top: 58px; */
}

body {
  background-color: rgb(246, 246, 246) !important;
  min-height: 100.0vh !important;
  /* font-family: 'Montserrat',sans-serif !important; */
  /* font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif !important; */
  /* font-family: Roboto,sans-serif !important; */
  /* font-family: 'Brush Script MT', cursive !important; */
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1.0s !important;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.router-anim-enter-active {
  animation: coming 0.5s;
  opacity: 0;
}
.router-anim-leave-active {
  animation: going 0.5s;
}
@keyframes going {
  from { transform: translateX(0); }
  to { transform: translateX(-150px); opacity: 0;  }
}
@keyframes coming {
  from { transform: translateX(-150px); opacity: 0; }
  to { transform: translateX(0); opacity: 1;  }
}

.slide-fade-enter-active {
    transition: all 0.8s ease !important;
  }
  .slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1.0, 0.5, 0.8, 1.0) !important;
  }
  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active до версии 2.1.8 */ {
    transform: translateY(-30px) !important;
    opacity: 0;
  }

  .slide-fade2-enter-active {
    transition: all 1.0s ease !important;
  }
  .slide-fade2-leave-active {
    transition: all 1.0s cubic-bezier(1.0, 0.5, 0.8, 1.0) !important;
  }
  .slide-fade2-enter, .slide-fade2-leave-to
  /* .slide-fade-leave-active до версии 2.1.8 */ {
    transform: translateY(-30px) !important;
    opacity: 0;
  }

  .slide-fade3-enter-active {
    transition: all 1.0s ease !important;
  }
  .slide-fade3-leave-active {
    transition: all 1.0s cubic-bezier(1.0, 0.5, 0.8, 1.0) !important;
  }
  .slide-fade3-enter, .slide-fade3-leave-to
  /* .slide-fade-leave-active до версии 2.1.8 */ {
    transform: translateX(-200px) !important;
    opacity: 0;
  }



.slide-navbar-enter-active {
  animation: slide-navbar-in 0.5s;
}
.slide-navbar-leave-active {
  animation: slide-navbar-in 0.5s reverse ease-out forwards;
}
@keyframes slide-navbar-in {
  0% {transform: translateY(-150px); opacity: 0; margin-bottom: -150px;}    
  100% {transform: translateY(0px); opacity: 1; margin-bottom: 0px;}
}


.slide-6-enter-active {
  animation: slide-6-in 0.7s;
}
/* .slide-6-leave-active {
  animation: slide-6-in 0.7s reverse ease-out forwards;
} */
@keyframes slide-6-in {
  0% {transform: translateX(-200px); opacity: 0}    
  100% {transform: translateX(0px); opacity: 1}
}

.bounce-enter-active {
  animation: bounce-in .9s;
}
.bounce-leave-active {
  animation: bounce-in .9s reverse;
}
@keyframes bounce-in {
  0% { transform: scale(0); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}


.demo-wrap {
  /* overflow: hidden; */
  position: relative;
  height: 100vh;
}
.demo-bg {
  opacity: 0.05;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.demo-content {
  position: relative;
  padding-bottom: 40px !important;
}

.shimmer {
  -webkit-mask:linear-gradient(-60deg,#000 30%,#0005,#000 60%) right/300% 100%;
  background-repeat: no-repeat;
  animation: shimmer 10s infinite;

}
@keyframes shimmer {
  100% {-webkit-mask-position:left}
}



</style>

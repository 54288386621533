<template>
    <div class="footer shimmer">

      <b-row class="text-center justify-content-center" :style="`min-height: ${heightCounter}vh;`" align-v="center">
        <!-- <b-col cols="12">ERKEN-CODE</b-col> -->
        <b-col cols="12" class="m-2">erken-code@mail.ru</b-col>
      </b-row>
    </div>  
</template>

<script>

export default {
  props: ['heightCounter'],
  name: "FooterComponent",
  data() {
    return {
      requestUser: null,

    }
  },
  methods: {
  },
  created() {
  },
  mounted() {

  }

};

</script>

<style>
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  /* background-color: red; */
  color: white;
  text-align: center;
  background-image: radial-gradient(rgb(0, 0, 0), rgb(140, 140, 140));
  overflow: hidden;
  z-index: 2;
}
</style>